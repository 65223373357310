<template>
  <div class="p-3 bg-black text-white">
    <div class="grid grid-cols-5">
      <!-- left side of fact sheets. -->
      <div class="top-left -mt-4">
        <a href="">
          <img
            class=""
            :src="require('@/assets/images/logo-v2.png')"
            alt="logo"
          >
        </a>
        <p class="pt-4 text-sm">
          User license
        </p>
        <h2 class="text-3xl">
          FACT SHEET
        </h2>
        <p class="pt-2 text-sm">
          <strong>  {{ selectedLayout?.createdUser }} </strong>
        </p>
        <p class="pb-2 text-sm">
          <strong>  {{ selectedLayout?.factSheetFilename }} </strong>
        </p>
        <p class="text-sm mb-4">
          View existing Fact Sheet
        </p>
        <base-button
          variant="btn-primary"
          class="mr-4"
          text="Click to open"
          @click="clickToOpenListOfFactSheets()"
        />
        <div class="middle-left pt-4">
          <p class="text-sm mb-4">
            To Create, choose Template 123
          </p>
          <ul class="flex">
            <li
              class="flex-1"
              @click="handleSelectTemplate(layout0)"
            >
              <img
                :src="require('@/assets/images/Template0.jpg')"
                alt="blank"
              >
            </li>
            <li
              class="flex-1"
              @click="handleSelectTemplate(layout1)"
            >
              <img
                :src="require('@/assets/images/Template1.jpg')"
                alt="template 1"
              >
            </li>
            <li
              class="flex-1"
              @click="handleSelectTemplate(layout2)"
            >
              <img
                :src="require('@/assets/images/Template2.jpg')"
                alt="template 2"
              >
            </li>
          </ul>
          <p class="py-4 text-sm">
            Or to add/modify content, click here:
          </p>
          <base-button
            variant="btn-primary"
            class="mr-4"
            text="Add a Widget"
            @click="addNewWidget('content')"
          />
          <base-button
            variant="btn-primary"
            class="mr-4"
            text="Add a Image"
            @click="addNewWidget('image')"
          />
        </div>
        <div class="bottom-left pt-6">
          <base-button
            class="mr-2 py-1"
            variant="btn-border-white"
            text="Save Fact Sheet..."
            @click="showHideRightBar(true, false)"
          />
          <base-button
            class="mr-0 py-1 text-gray-900"
            variant="btn-border-white"
            text="Publish Fact Sheet..."
            @click="showHideRightBar(true, true)"
          />
        </div>
      </div>

      <!-- middle part of fact sheets. -->
      <div class="col-span-3 p-2 text-black bg-white overflow-hidden h-253 print-area-size">
        <div
          v-if="isFetchingFactSheetData"
          class="bg-gray-400 h-full flex justify-center items-center"
        >
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading Fact Sheet...
        </div>
        <div
          v-else
          class="bg-gray-400 h-full"
        >
          <grid-layout
            v-model:layout="layout"
            :col-num="12"
            :row-height="30"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[0, 0]"
            :use-css-transforms="true"
          >
            <grid-item
              v-for="(item, itemIndex) in layout"
              :key="item.i"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :class="clickedGridItemIndex === itemIndex ? 'z-10' : ''"
              drag-allow-from=".vue-draggable-handle"
              drag-ignore-from=".no-drag"
              @contextmenu="updateFocus(itemIndex)"
            >
              <image-widget
                v-if="item.widgetType === 'image'"
                v-model="item.content"
                :widget-index="itemIndex"
                @deleteWidget="handleDeleteWidget(item.i)"
              />
              <content-widget
                v-else
                v-model:heading="item.heading"
                v-model:content="item.content"
                :widget-index="itemIndex"
                @deleteWidget="handleDeleteWidget(item.i)"
              />
              <base-svg
                v-if="item.widgetType === 'content'"
                class="h-4 w-4 hover:text-white inline-block text-gray-900 cursor-pointer remove"
                src="icons/cross.svg"
                :svg-attributes="{
                  class: 'h-full w-full'
                }"
                tag="span"
                @click="handleDeleteWidget(item.i)"
              />
              <div
                v-if="item.widgetType === 'content'"
                class="text"
              >
                <base-svg
                  v-if="item.widgetType === 'content'"
                  class="h-4 w-4 hover:text-white inline-block text-gray-900 cursor-pointer vue-draggable-handle"
                  src="icons/drag-icon.svg"
                  :svg-attributes="{
                    class: 'h-full w-full'
                  }"
                  tag="span"
                />
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <!-- Right Side of fact sheets. -->
      <div
        v-if="toShowRightBar"
        class="pt-0 ml-2 -mt-4 -mr-4 right-side"
      >
        <form
          class="bg-white p-4"
          action=""
        >
          <span
            v-if="isPublish"
            class="text-3xl text-black"
          >
            Publish
          </span>
          <span
            v-else
            class="text-3xl text-black"
          >
            Save
          </span>
          <div>
            <label class="text-black"><b> Title </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              v-model="factSheetTitle"
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Title"
              type="text"
            >
            <small v-if="!factSheetTitle" class="text-red-500 block block text-right">Required</small>
          </div>
          <div>
            <label class="text-black"> <b> Full Name </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              v-model="factSheetFilename"
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4 lowercase"
              placeholder="Enter File Name"
              type="text"
            >
            <small v-if="!factSheetFilename.includes('.pdf')" class="text-red-500 block text-right">File Name must have `.pdf` as extension</small>
          </div>
          <div>
            <label class="text-black"> <b> MetaData </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              v-model="metadata"
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Meta Data"
              type="text"
            >
            <small v-if="!metadata" class="text-red-500 block text-right">Required</small>
          </div>
          <div>
            <label class="text-black"> <b> Display Order </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              v-model="displayOrder"
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Display Order (digits only)"
              type="number"
            >
            <small v-if="!displayOrder" class="text-red-500 block text-right">Required</small>
          </div>
          <div>
            <label class="text-black"> <b> Iteration </b>
              <span class="text-red-500">*</span>
            </label>
            <base-select
              v-model="iteration"
              class="text-sm max-w-full w-40 text-left mr-3 text-black bg-white mt-2 mb-5"
              text-container-class="w-full h-7 inline-block p-1 text-left"
              :btn-props="{
                class: 'focus:outline-none text-primary-gray rounded-sm border border-custom-gray-8 text-custom-gray-7 flex'
              }"
              dropdown-class="max-h-32 rounded-sm py-1"
              list-item-class="cursor-default select-none relative py-1 pl-2 pr-9 hover:bg-custom-gray-2"
              :options="iterationOption"
              use-selected-option-icon
            />
          </div>
          <div v-if="isPublish">
            <label class="text-black"> <b> Choose a PDF </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              ref="fileUploadedToPublish"
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              multiple
              type="file"
              @change.prevent="handleFileUpload"
            >
          </div>
          <div class="p-2">
            <base-button
              class="mr-4 py-1 w-full"
              :is-loading="isUploadFileLoading"
              variant="btn-green"
              :text="isPublish ? 'Publish' : 'Save'"
              @click="uploadFiles(isPublish ? 'publishClicked' : 'saveClicked')"
            />
          </div>
          <div class="p-2">
            <base-button
              class="mr-4 py-1 w-full"
              variant="btn-primary"
              text="Close"
              @click="showHideRightBar(false, null)"
            />
          </div>
        </form>
      </div>
    </div>
    <!-- confirm delete modal -->
    <base-modal
      v-model="toShowModal"
      overlay-class="bg-gray-900 opacity-60"
      popup-class="bg-white text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
    >
      <template #modalHeader>
        <header class="px-2.5 py-2 bg-custom-gray-10 flex justify-between items-center">
          <span class="text-black text-sm font-semibold">
            {{ deleteModal ? 'Delete Widget' : 'Select Template' }}
          </span>
          <base-svg
            class="h-5 w-5 text-custom-gray-7 inline-block hover:text-gray-900 cursor-pointer"
            src="icons/cross.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
            @click="toggelModal(false)"
          />
        </header>
      </template>
      <template #modalBody>
        <div class="px-4 py-3">
          <div class="text-justify text-sm mb-2 leading-5">
            Are you sure you want to {{ deleteModal ? 'Delete this widget' : 'create this template?' }}
          </div>
          <div class="text-right">
            <base-button
              class="mr-3"
              variant="btn-primary"
              text="Yes"
              @click="deleteModal ? deleteWidget() : selectTemplate()"
            />
            <base-button
              variant="btn-link"
              text="No"
              @click="toggelModal(false)"
            />
          </div>
        </div>
      </template>
    </base-modal>

    <!-- list of fact sheets -->
    <base-modal
      v-model="showListOfFactSheetsModal"
      modal-title="Fact Sheets"
    >
      <template #modalBody>
        <div
          class="px-2 py-3 overflow-scroll"
          style="height: 60vh"
        >
          <div class="font-sm text-center mb-2">
            <brand-assurance-table
              v-if="!isFactSheetsLoading"
              v-model="factSheetsListing.tableData"
              :show-check-box-column="false"
              root-element-class="mb-20"
            >
              <!-- inject link in submission ID column -->
              <template
                v-for="(submissionIdCellSlotName, submissionIndex) in submissionIdCellSlotNames"
                :key="submissionIdCellSlotName"
                #[submissionIdCellSlotName]
              >
                <span
                  class="underline cursor-pointer"
                  @click="openSelectedFactSheet(factSheetsListing?.tableData?.data[submissionIndex])"
                >
                  {{ factSheetsListing?.tableData?.data[submissionIndex]?.factSheetTitle }}
                </span>
              </template>
            </brand-assurance-table>
            <span v-else>
              <base-svg
                class="h-4 w-4 mr-1 text-primary-red inline-block"
                src="icons/circleSpinner.svg"
                tag="span"
              />Loading ...
            </span>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import useBaTable from '@/hooks/baTable.js';
import { GridItem, GridLayout } from 'vue-grid-layout';
import { computed, defineAsyncComponent, onMounted, reactive, ref, nextTick } from 'vue';
import moment from 'moment';
import axios from 'axios';
import pLimit from 'p-limit';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, FACTSHEET_FETCHING_FAILED, FACTSHEET_UPLOADED_FAILED, FACTSHEET_UPLOADED_SUCCESSFULLY, SUCCESS, WARNING } from '@/constants/alerts';

export default {
    name: 'FactShests',

    components: {
        GridItem,
        GridLayout,
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        ContentWidget: defineAsyncComponent(() => import('@/components/fact-sheets/ContentWidget.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        ImageWidget: defineAsyncComponent(() => import('@/components/fact-sheets/ImageWidget.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup () {
        const store = useStore();
        const isPublish = ref(false);
        const deleteModal = ref(false);
        const toShowModal = ref(false);
        const toShowRightBar = ref(false);
        const showListOfFactSheetsModal = ref(false);
        const { notificationsStack } = useToastNotifications();
        const displayOrder = ref(null);
        const factSheetFilename = ref('');
        const factSheetTitle = ref('');
        const metadata = ref('');
        const iteration = ref('');

        const iterationFiltersMap = ref(
            [
                {
                    label: 'Classic',
                    key: 'Classic'
                },
                {
                    label: 'Movie',
                    key: 'Movie'
                },
                {
                    label: 'Retro',
                    key: 'Retro'
                },
                {
                    label: 'Television',
                    key: 'Television'
                },
                {
                    label: 'Ultimate',
                    key: 'Ultimate'
                }
            ]
        );

        const layout = ref([]);
        const layoutToBeLoaded = ref([]);
        const widgetToBeDeleted = ref('');
        const widgetDefaultWidth = ref(4);
        const widgetDefaultHeight = ref(3);

        const { generateTableSlotNamesByColumnKey } = useBaTable();
        const submissionIdCellSlotNames = computed(() => generateTableSlotNamesByColumnKey(factSheetsListing.tableData, 'factSheetTitle', 0));

        const factSheetsListing = reactive({
            tableData: {
                columns: [
                    {
                        label: 'ID',
                        key: 'factSheetId'
                    },
                    {
                        label: 'Title',
                        key: 'factSheetTitle'
                    },
                    {
                        label: 'PDF File Name',
                        key: 'factSheetFilename'
                    },
                    {
                        label: 'Display Order',
                        key: 'displayOrder'
                    },
                    {
                        label: 'Iteration',
                        key: 'iteration'
                    },
                    {
                        label: 'Created By',
                        key: 'createdUser'
                    },
                    {
                        label: 'Created Date',
                        key: 'creationDate'
                    },
                    {
                        label: 'Last Modified By',
                        key: 'lastmodifiedUser'
                    },
                    {
                        label: 'Last Modified Date',
                        key: 'lastmodifiedDate'
                    },
                    {
                        label: 'Status',
                        key: 'factSheetStatus'
                    }
                ],
                data: []
            },
            page: 1,
            limit: 5,
            pageCount: 0
        });

        const layout0 = ref(
            [{ x: 0, y: 0, w: 4, h: 5, i: '0', widgetType: 'image' }]
        );

        const layout1 = ref([
            { x: 0, y: 0, w: 9, h: 12, i: '0', widgetType: 'image' },
            { x: 0, y: 7, w: 12, h: 10, i: '1', widgetType: 'content' },
            { x: 9, y: 2, w: 3, h: 6, i: '2', widgetType: 'content' },
            { x: 9, y: 0, w: 3, h: 6, i: '3', widgetType: 'content' },
            { x: 0, y: 4, w: 12, h: 12, i: '4', widgetType: 'content' },
            { x: 0, y: 10, w: 12, h: 13, i: '5', widgetType: 'content' }
        ]);

        const layout2 = ref([
            { x: 0, y: 0, w: 5, h: 8, i: '0', content: 'ok123', heading: '', widgetType: 'image' },
            { x: 4, y: 6, w: 4, h: 8, i: '1', content: '', heading: '', widgetType: 'content' },
            { x: 5, y: 0, w: 7, h: 8, i: '2', content: '', heading: '', widgetType: 'image' },
            { x: 3, y: 12, w: 9, h: 8, i: '3', content: '', heading: '', widgetType: 'content' },
            { x: 0, y: 6, w: 4, h: 8, i: '4', content: '', heading: '', widgetType: 'content' },
            { x: 0, y: 12, w: 3, h: 8, i: '5', content: '', heading: '', widgetType: 'content' },
            { x: 0, y: 18, w: 5, h: 7, i: '6', content: '', heading: '', widgetType: 'content' },
            { x: 5, y: 18, w: 7, h: 7, i: '7', content: '', heading: '', widgetType: 'content' },
            { x: 8, y: 6, w: 4, h: 8, i: '8', content: '', heading: '', widgetType: 'content' },
            { x: 0, y: 23, w: 3, h: 6, i: '9', content: '', heading: '', widgetType: 'content' },
            { x: 3, y: 23, w: 9, h: 6, i: '10', content: '', heading: '', widgetType: 'content' },
            { x: 0, y: 27, w: 12, h: 10, i: '11', content: '', heading: '', widgetType: 'content' }
        ]);

        const clickedGridItemIndex = ref(null);
        const iterationOption = computed(() => {
            return iterationFiltersMap.value?.map(filter => filter?.label);
        });

        onMounted(() => {
            layout.value = layout0.value?.map(item => {
                return {
                    ...item,
                    content: '',
                    heading: ''
                };
            });
        });

        const isFetchingFactSheetData = ref(false);
        const openSelectedFactSheet = async (selectedFile) => {
            showListOfFactSheetsModal.value = false;
            isFetchingFactSheetData.value = true;
            await axios.get(selectedFile?.presignedUrl)?.then(res => {
                selectedFile.layout = res?.data;
            });

            selectTemplate(selectedFile);
        };

        const isFactSheetsLoading = ref(false);
        const fetchedFactSheetsData = computed(() => store.getters['factSheet/getFactSheetsList']);
        const fetchFactSheets = async () => {
            try {
                isFactSheetsLoading.value = true;

                await store.dispatch('factSheet/fetchFactSheets');
                if (fetchedFactSheetsData.value) {
                    fetchedFactSheetsData.value.forEach(data => {
                        data.creationDate = moment(data?.creationDate)?.format('MM/DD/YYYY');
                        data.lastmodifiedDate = moment(data?.lastmodifiedDate)?.format('MM/DD/YYYY');
                    });

                    factSheetsListing.tableData.data = fetchedFactSheetsData.value;
                }
            } catch (e) {
                isFactSheetsLoading.value = false;
            } finally {
                isFactSheetsLoading.value = false;
            }
        };

        const fileUploadedToPublish = ref(null);
        const userId = computed(() => store.getters['auth/getUserId']);
        const uploadedFile = ref(null);

        const handleFileUpload = (event) => {
            uploadedFile.value = event;
        };

        const isUploadFileLoading = ref(false);
        const uploadFiles = async (clicked) => {
            try {
                if (!displayOrder.value || !factSheetFilename?.value?.includes('.pdf') || !metadata.value || !iteration.value || !factSheetTitle.value) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'Please fill in Required fields.'
                    });
                    return;
                }
                isUploadFileLoading.value = true;
                if (clicked === 'saveClicked') {
                    const bodyPayload = {
                        createdUser: userId.value,
                        creationDate: Date.now(),
                        displayOrder: displayOrder.value,
                        factSheetFilename: factSheetFilename.value,
                        metadata: metadata.value,
                        iteration: iteration.value,
                        factSheetTitle: factSheetTitle.value
                    };
                    const savedFactSheet = await store.dispatch('factSheet/saveFetchFactSheets', { bodyPayload });
                    const JSONToBlob = new Blob([JSON.stringify(layout.value, null, 2)], {
                        type: 'application/json'
                    });

                    const fileBlob = new File([JSONToBlob], 'JsonToBlob');
                    uploadAsFile(savedFactSheet?.presignedUrl, fileBlob);
                } else {
                    if (!uploadedFile.value) {
                        notificationsStack.value.push({
                            type: ERROR,
                            message: 'You must Upload File.'
                        });
                        return;
                    };

                    const specialChars = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
                    const specialCharsExists = specialChars.test(uploadedFile?.value[0]?.name);

                    if (specialCharsExists) {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: 'File Name contains special characters.'
                        });
                        return;
                    }
                    const bodyPayload = {
                        createdUser: userId.value,
                        creationDate: Date.now(),
                        displayOrder: displayOrder.value,
                        factSheetFilename: factSheetFilename.value,
                        metadata: metadata.value,
                        iteration: iteration.value,
                        factSheetTitle: factSheetTitle.value
                    };
                    const publishedFactSheet = await store.dispatch('factSheet/publishFetchFactSheets', { bodyPayload });
                    uploadAsFile(publishedFactSheet.presignedUrl, uploadedFile.value);
                }
            } catch (e) {
                isUploadFileLoading.value = false;
                console.error(e);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${FACTSHEET_UPLOADED_FAILED}`
                });
            }
        };

        const concurrentlyExecutedLimit = pLimit(12);
        const uploadAsFile = (url, fileBlob) => {
            // Add to the concurrently executed queue
            const uploadPromise = concurrentlyExecutedLimit(() => {
            // Make the PUT call
                return axios.put(url, fileBlob, {
                    onUploadProgress: (progressEvent) => {
                        const tmpProgress = Math.floor((progressEvent?.loaded / progressEvent?.total) * 100);
                        // uploadFileTracker.value[file.objectId].progress[0] = tmpProgress;
                        if (tmpProgress >= 100) console.log(`Active Count ${concurrentlyExecutedLimit.activeCount} .. Pending Count ${concurrentlyExecutedLimit.pendingCount}`);
                    }
                });
            });

            // Add a handler
            uploadPromise.then((putResp) => {
                isUploadFileLoading.value = false;
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: `${FACTSHEET_UPLOADED_SUCCESSFULLY} - ${fileBlob?.filename?.toLowerCase()}`
                });
            }).catch((uploadErr) => {
                isUploadFileLoading.value = false;
                console.error(uploadErr);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${FACTSHEET_UPLOADED_FAILED} - ${fileBlob?.filename?.toLowerCase()} - ${uploadErr}`
                });
            });
        };

        const toggelModal = (val) => {
            toShowModal.value = val;
        };

        const showHideRightBar = (val, val2) => {
            isPublish.value = val2;
            toShowRightBar.value = val;
        };

        const selectedLayout = ref(null);
        const selectTemplate = async (selectedFileComing = null) => {
            try {
                if (selectedFileComing) {
                    selectedLayout.value = null;
                    layout.value = [];
                    selectedLayout.value = selectedFileComing;

                    /* ...populating right bar after fetching fact sheet... */
                    displayOrder.value = selectedLayout.value?.displayOrder;
                    factSheetFilename.value = selectedLayout.value?.factSheetFilename;
                    factSheetTitle.value = selectedLayout.value?.factSheetTitle;
                    metadata.value = selectedLayout.value?.metadata;
                    iteration.value = selectedLayout.value?.iteration;

                    await nextTick(); // ...to update DOM...
                    layout.value = selectedFileComing?.layout?.map((lay, i) => {
                        return {
                            ...lay,
                            content: lay?.content,
                            heading: lay?.heading
                        };
                    });
                    isFetchingFactSheetData.value = false;
                } else {
                    layout.value = [];
                    layout.value = layoutToBeLoaded?.value?.map(item => {
                        return {
                            ...item,
                            content: '',
                            heading: ''
                        };
                    });
                    toggelModal(false);
                }
            } catch (e) {
                console.error(e);
                isFetchingFactSheetData.value = false;
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${FACTSHEET_FETCHING_FAILED}`
                });
            } finally {
                isFetchingFactSheetData.value = false;
            }
        };

        const handleSelectTemplate = (layout) => {
            deleteModal.value = false;
            toggelModal(true);
            layoutToBeLoaded.value = layout;
        };

        const updateFocus = (index) => {
            clickedGridItemIndex.value = index;
        };

        const addNewWidget = (val) => {
            const reSizedLayout = {
                x: (layout.value.length * widgetDefaultWidth.value) % 12,
                y: layout.value.length + 12,
                w: widgetDefaultWidth.value,
                h: widgetDefaultHeight.value,
                i: (layout.value.length).toString(),
                content: '',
                heading: '',
                widgetType: val === 'content' ? 'content' : 'image'
            };
            layout.value.push(reSizedLayout);
            window.dispatchEvent(new Event('resize'));
        };

        const deleteWidget = () => {
            const index = layout.value?.map(item => item.i)?.indexOf(widgetToBeDeleted.value);
            layout.value.splice(index, 1);
            toggelModal(false);
        };

        const handleDeleteWidget = (val) => {
            deleteModal.value = true;
            toggelModal(true);
            widgetToBeDeleted.value = val;
        };

        const clickToOpenListOfFactSheets = () => {
            fetchFactSheets();
            showListOfFactSheetsModal.value = true;
        };

        return {
            // data props
            clickToOpenListOfFactSheets,
            fileUploadedToPublish,
            isFactSheetsLoading,
            displayOrder,
            factSheetFilename,
            factSheetTitle,
            metadata,
            openSelectedFactSheet,
            factSheetsListing,
            showListOfFactSheetsModal,
            toShowModal,
            deleteModal,
            toShowRightBar,
            isPublish,
            iteration,
            iterationFiltersMap,
            widgetToBeDeleted,
            widgetDefaultWidth,
            widgetDefaultHeight,
            layoutToBeLoaded,
            layout,
            layout0,
            layout1,
            layout2,
            clickedGridItemIndex,
            // computed
            iterationOption,
            // methods
            selectedLayout,
            uploadFiles,
            handleFileUpload,
            toggelModal,
            showHideRightBar,
            selectTemplate,
            updateFocus,
            addNewWidget,
            deleteWidget,
            handleDeleteWidget,
            handleSelectTemplate,
            submissionIdCellSlotNames,
            isUploadFileLoading,
            isFetchingFactSheetData
        };
    }
};

</script>

<style>
@layer components {
  .vue-grid-item  {
    @apply bg-white;
    border: 2px solid;
  }
}

@media print {
  .top-left, .right-side, .remove, .vue-draggable-handle, title {
    display: none;
  }
}

.print-area-size {
    width: 28cm;
    height: 37.7cm;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 7px;
    left: 2px;
    padding: 0px 0px 6px 0;
    cursor: pointer;
}

.remove {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
